var nv_tabs = function(event)
{
   event.preventDefault();

    $(this).parent().addClass("nv-tab-current");
    $(this).parent().siblings().removeClass("nv-tab-current");
    var tab 	= $(this).attr("href");
    var tabs 	= $(this).parents(".nv-tabs").eq(0);

    // The reason for doing .children().children() is because we don't want to affect embedded nv-tabs
    tabs.children(".nv-tab").children(".nv-tab-content").not(tab).css("display", "none");
    tabs.children(".nv-tab").children(tab).fadeIn(500);
};

var nv_tabs_select = function(event)
{
	event.preventDefault();

	let classes = jQuery(this).val().split("|");

	for(var i in classes)
	{
		var tab = classes[i];
		var tabs 	= $(tab).parents(".nv-tabs").eq(0);

		// The reason for doing .children().children() is because we don't want to affect embedded nv-tabs
	    tabs.children(".nv-tab").children(".nv-tab-content").not(tab).css("display", "none");
	    tabs.children(".nv-tab").children(tab).fadeIn(500);
	}
};

jQuery.nvTabs = function(selector)
{
	if(typeof selector != "string")
	{
		selector = ".nv-tabs";
	}

	$(selector + " .nv-tabs-nav a.nv-tab-link").unbind("click", nv_tabs).click(nv_tabs);
};

jQuery.nvTabsSelect = function(selector)
{
	if(typeof selector != "string")
	{
		selector = ".nv-tabs-select";
	}

	$(selector).unbind("change", nv_tabs_select).change(nv_tabs_select);
};
